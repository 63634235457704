@font-face
  font-family: caveat
  src: url(assets/font/Caveat/Caveat-Regular.woff2) format("woff2")

@font-face
  font-family: lato
  src: url(assets/font/Lato/Lato-Regular.woff2) format("woff2")

@font-face
  font-family: playfair_display
  src: url(assets/font/Playfair_Display/PlayfairDisplay-Regular.woff2) format("woff2")
