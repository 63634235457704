body
  --darkred: #620606
  --redOpacity: rgba(255,0,0,.7)
  --darkred-white: #620606
  --red: #d50037
  --darkyellow: #936E10
  --yellow: #b88914
  --extralightgray: #E4E4E4
  --lightgray: #A7A7A7
  --gray: #979797
  --darkgray: #676767
  --extradarkgray: #343434
  --listgray: #A4A4A4
  --white-background: #F3F3F3
  --layout-background: #FFFFFF
  --list-text-color: #676767
  --list-separator-color: #A7A7A7
  --navbar-background: #E4E4E4
  --icon-color: #808080
  --selected-icon-color: #b88914
  --icon-border-color: #00000000
  --darkred-admin: #FFFFFF
  --white: #FFFFFF
  --black: #000000
  --a-filter-background: #d9d9d9
  --filter-background: #E4E4E4
  --filter-url: url("/assets/icons/flechita.svg")
  --invert-color: invert(0)
  --gold: #daa300
  --silver: #9d9fa2
  --bronze: #c75808
  --green: #718000
  --whiteOpacity: rgba(245,249,191,.7)

body.dark
  --darkred: #620606
  --redOpacity: rgba(255,0,0,.7)
  --darkred-white: #FFFFFF
  --red: #d50037
  --darkyellow: #936E10
  --yellow: #b88914
  --extralightgray: #c4c4c4
  --lightgray: #A7A7A7
  --gray: #979797
  --darkgray: #E4E4E4
  --extradarkgray: #343434
  --listgray: #A4A4A4
  --layout-background: #E4E4E4
  --list-text-color: #858585
  --list-separator-color: #00000000
  --navbar-background: #363535
  --icon-color: #808080
  --selected-icon-color: #000
  --icon-border-color: #ffffff
  --darkred-admin: #620606
  --white: #000000
  --black: #FFFFFF
  --a-filter-background: #d9d9d9
  --filter-background: #8E8E8E
  --filter-url: url("/assets/icons/dark/flechita.svg")
  --invert-color: invert(1)
  --gold: #daa300
  --silver: #9d9fa2
  --bronze: #c75808
  --green: #718000
  --whiteOpacity: rgba(245,249,191,.7)

// Small tablets and large smartphones (landscape view)
$screen-sm-min: 576px
// Small tablets (portrait view)
$screen-md-min: 768px
// Tablets and small desktops
$screen-lg-min: 992px
// Large tablets and desktops
$screen-xl-min: 1200px
$screen-min-height: 600px


// Small devices
@mixin sm
  @media (min-width: #{$screen-sm-min})
    @content

// Medium devices
@mixin md
  @media (min-width: #{$screen-md-min})
    @content

// Large devices
@mixin lg
  @media (min-width: #{$screen-lg-min})
    @content

// Extra large devices
@mixin xl
  @media (min-width: #{$screen-xl-min})
    @content

$outer-margin: 1.25rem
$outer-vmargin: 1.5vh
$top-margin: 3rem
$logo-height: 3rem
$color-error: red
$cart-height: 3.75rem


body
  margin: 0
  padding: 0
  top: 0
  left: 0
  font-family: lato, Arial, sans-serif

body.modal-open
  overflow: hidden

body.dark
  .dashboard__historical .movement-title,
  .button--clear
    color: white
  .cart-item__remove-image,
  .cart-item__counter-image,
  .category__icon
    filter: saturate(0) brightness(11)

.modal
  position: fixed
  top: 0
  left: 0
  height: 100vh
  width: 100vw

.modal-box__container
  position: absolute
  height: 100%
  width: 100%
  display: flex
  align-items: center
  justify-content: center

.modal-box
  position: relative
  background: white
  height: 40vh
  width: 90%
  max-width: 500px
  max-height: 350px
  min-height: 350px

app-public-navbar
  display: block
  position: sticky
  top: 0
  padding: 0
  z-index: 900

app-prizes-management
  position: relative

a:hover, a:visited, a:link, a:active
  text-decoration: none

form, input, select
  font-size: 1rem

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active
  font-size: 1rem
  -webkit-box-shadow: 0 0 0px 50px white inset

.invalid
  border-bottom: 1px solid var(--red) !important

.uncorked-title
  font-weight: bold
  font-style: italic
  text-align: center
  font-family: playfair_display, "Times New Roman", serif

.--manager-user
  color: var(--darkyellow)

.layout
  display: flex
  flex-direction: column
  height: 100%
  width: 100%
  margin: 0
  background-color: var(--layout-background)

.button
  cursor: pointer
  margin: 1rem 0
  min-width: 8rem
  padding: 5px
  min-height: 2.7rem
  background-color: var(--darkred)
  display: flex
  justify-content: center
  align-items: center
  font-family: lato, Arial, sans-serif
  font-size: 1rem
  color: white
  border-radius: 0.125rem
  font-weight: bold
  border: 0
  box-sizing: border-box
  &:disabled
    background-color: var(--extralightgray)
    color: var(--gray)
  &.--delete
    background-color: var(--red)

.filter__select
  height: 1.8125rem
  width: 70%
  border: 0
  background: var(--filter-background) var(--filter-url) no-repeat
  background-position: calc(100% - 0.75rem) center
  appearance: none
  padding: 0 1rem
  color: var(--black)

div.mat-paginator-container
  justify-content: center
  font-size: 1rem
  margin-top: 30px
  button
    margin: 0 5px
    border-radius: 50%
    background: var(--extralightgray)
    width: 30px
    height: 30px
    display: flex
    align-items: center
    justify-content: center
  .mat-button-wrapper
    line-height: 30px
.mat-paginator-navigation-first
  order: 1
.mat-paginator-navigation-previous
  order: 2
.mat-paginator-range-label
  order: 3
.mat-paginator-navigation-next
  order: 4
.mat-paginator-navigation-last
  order: 5
.mat-paginator-hide
  visibility: hidden

gallery
  gallery-thumbs, gallery-counter
    display: none
  gallery-image
    padding: 40px 0
    padding-top: calc(env(safe-area-inset-top) + 40px)
  .g-btn-close
    padding-top:  calc( env(safe-area-inset-top) + 15px)
    svg
      width: 20px
      height: 20px


/////////////////////////////WYSIWYG


.wysiwyg
  display: block
  max-width: 100%
  width: 100%
  @include md
    max-width: 770px
  code
    font-size: .9rem
.angular-editor-button
  padding: 5px !important
.fa
  display: block !important
  font: 0/1 Arial !important
  font-size: 0 !important
  color: transparent !important
  background-size: 15px
  background-repeat: no-repeat
  background-position: center
  width: 20px
  height: 20px
  &:before
    display: none !important
.fa-bold
  background-image: url('/assets/icons/wysiwyg/bold.svg')
.fa-italic
  background-image: url('/assets/icons/wysiwyg/italic.svg')
.fa-code
  background-image: url('/assets/icons/wysiwyg/code.svg')
.fa-undo
  background-image: url('/assets/icons/wysiwyg/reply.svg')
.fa-repeat
  background-image: url('/assets/icons/wysiwyg/share.svg')
.fa-link
  background-image: url('/assets/icons/wysiwyg/link.svg')
.fa-chain-broken
  background-image: url('/assets/icons/wysiwyg/link-slash.svg')
.fa-remove
  background-image: url('/assets/icons/wysiwyg/xmark.svg')


@import src/app/shared/range-slider/range-slider.component.sass
